import { tns } from "tiny-slider";
window.tns = tns;

window.screen_xs = 480;
window.screen_xs_min = screen_xs;
window.screen_phone = screen_xs_min;
window.screen_sm = 768;
window.screen_sm_min = screen_sm;
window.screen_tablet = screen_sm_min;
window.screen_md = 992;
window.screen_md_min = screen_md;
window.screen_desktop = screen_md_min;
window.screen_lg = 1200;
window.screen_lg_min = screen_lg;
window.screen_lg_desktop = screen_lg_min;
window.screen_xs_max = (screen_sm_min - 1);
window.screen_sm_max = (screen_md_min - 1);
window.screen_md_max = (screen_lg_min - 1);

window.lastScrollTop = 0;
window.delta = 5;

window.get_vw = function() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

window.get_vh = function() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

window.hasScrolled = function() {
    var st = $(this).scrollTop();

    // Make sure they scroll more than delta
    if(Math.abs(lastScrollTop - st) <= delta)
        return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        $('nav').removeClass('nav-down').addClass('nav-up');
    } else {
        // Scroll Up
        if(st + $(window).height() < $(document).height()) {
            $('nav').removeClass('nav-up').addClass('nav-down');
        }
    }

    lastScrollTop = st;
}

window.update_breadcrumb = function() {
    var breadcrumb = $('nav[aria-label="breadcrumb"]');

    if (breadcrumb.length && breadcrumb.find('.breadcrumb-item').length > 3) {
        $('.breadcrumb-item.act-expand-breadcrumb, .breadcrumb-item.act-expand-breadcrumb-desktop').on('click', function() {
            breadcrumb.find('.breadcrumb .breadcrumb-item').show();
            $('.breadcrumb-item.act-expand-breadcrumb, .breadcrumb-item.act-expand-breadcrumb-desktop').hide();
        });
    }
}

window.update_nav_top = function() {
    var header_height = $('header').height();
    if ($('.linepromos').length) {
        var promo_height = $('.linepromos').height();
    } else {
        var promo_height = 0;
    }
    var nav_top = header_height + promo_height;
    var submenu_height = get_vh();

    if (get_vw() >= screen_md_min) {
        $('nav').css('top', nav_top + 'px');
        content_padding = 0;
    } else {
        $('nav, #pages_mega_menu #mega_menu .list-dropdown .submenu_list, #pages_mega_menu #mega_menu .list-dropdown .row.level-3').css('top', nav_top + 'px');
        content_padding = promo_height;
    }

    $('#content_layout').css('padding-top', content_padding + 'px');
    submenu_height = submenu_height - nav_top;

    if (get_vw() <= screen_sm_max) {
        $('nav').css('height', submenu_height + 'px');
    } else {
        $('nav').css('height', '');
    }
}

window.update_wishlist_info = function() {
    $.ajax({
        url: '?display=content_types/wishlist/wishlist_info',
        method: 'post',
        success: function(html) {
            $('#wishlistInfo').replaceWith(html);
        }
    });
}

window.doModal = function(content) {
    let html =  '<div class="modal fade" id="dynamicModal" tabindex="-1" role="dialog">';
    html += '<div class="modal-dialog modal-dialog-centered" role="document">';
    html += '<div class="modal-content">';
    html += '<div class="modal-header">';
    html += '<button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>';
    html += '</div>';
    html += '<div class="modal-body">';
    html += content;
    html += '</div>'; 	// body
    html += '</div>';  	// content
    html += '</div>';  	// dialog
    html += '</div>';  	// modal
    $('body').append(html);
    $("#dynamicModal").modal();
    $("#dynamicModal").modal('show');
    $('#dynamicModal').on('hidden.bs.modal', function (e) {
        $(this).remove();
    });
}

window.rotateMenuIcon = function(x) {
    x.classList.toggle("change");
}

window.ajaxnav = function(params) {
    // ajaxnav(url, container, template, update_address, append_history)
    if (typeof params !== 'object') {
        params = {
            url: arguments[0],
            container: arguments[1],
            template: arguments[2],
            update_address: arguments[3] !== undefined ? arguments[3] : true,
            append_history: arguments[4] !== undefined ? arguments[4] : true,
            show_loader: arguments[5] !== undefined ? arguments[5] : true,
        }
    }

    // uzpidom default'iniais parametrais
    params = $.extend({
        update_address: true,
        append_history: true,
        show_loader: true,
        method: 'GET',
        data: {},
        error: function(data, textStatus, jqXHR) {
            $('#ajax_loader').hide();
            if (params.callback !== undefined) {
                params.callback(data, textStatus, jqXHR);
            }
        },
        success: function(data, textStatus, jqXHR) {
            $('#ajax_loader').hide();
            if (typeof data == 'object') {
                if (data.reload === true) {
                    $('#ajax_loader').show();
                    window.location.href = data.url;
                } else {
                    ajaxnav($.extend({
                        container: params.container,
                        template: params.template,
                        update_address: params.update_address,
                        append_history: params.append_history,
                        show_loader: params.show_loader
                    }, data));
                }
            } else if (typeof data == 'string') {
                if (params.container !== undefined) {
                    var $data = $(data);

                    if (!$data.attr('id')) {
                        $data.attr('id', 'id-' + Math.random().toString(36).substr(2, 16));
                    }
                    var $previous = $(params.container).replaceWith($data);


                    if (params.update_address) {
                        var pso = {
                            template: params.template,
                            container: params.container
                        }
                        var final_url = jqXHR.getResponseHeader('X-AJAXNAV-URL');
                        if (!final_url) final_url = params.url;
                        if (params.append_history) {
                            // window.history.replaceState(pso, '', window.location);
                            window.history.pushState(pso,'', final_url);
                        } else {
                            window.history.replaceState(pso,'', final_url);
                        }
                    }
                }
                if (params.callback !== undefined) {
                    params.callback(data, textStatus, jqXHR);
                }
            }
        }
    }, params);

    // vykdom ajax request'a
    $.ajax({
        url: params.url + (!params.url.match(/\?/) ? '?' : (!params.url.match(/&$/) ? '&' : '')) + 'display=' + params.template,
        method: params.method,
        data: params.data,
        success: params.success,
        beforeSend: function() {
            if(params.show_loader) {
                $('#ajax_loader').show();
            }
        },
    });
}

window.toLatin = function(str) {
    let from = [
        '¹','²','³','°','æ','ǽ','À','Á','Â','Ã','Å','Ǻ','Ă','Ǎ','Æ','Ǽ','à','á','â','ã','å','ǻ','ă','ǎ','ª','@','Ĉ','Ċ','ĉ','ċ','©','Ð','Đ','ð','đ','È','É',
        'Ê','Ë','Ĕ','Ė','è','é','ê','ë','ĕ','ė','ƒ','Ĝ','Ġ','ĝ','ġ','Ĥ','Ħ','ĥ','ħ','Ì','Í','Î','Ï','Ĩ','Ĭ','Ǐ','Į','Ĳ','ì','í','î','ï','ĩ','ĭ','ǐ','į',
        'ĳ','Ĵ','ĵ','Ĺ','Ľ','Ŀ','ĺ','ľ','ŀ','Ñ','ñ','ŉ','Ò','Ô','Õ','Ō','Ŏ','Ǒ','Ő','Ơ','Ø','Ǿ','Œ','ò','ô','õ','ō','ŏ','ǒ','ő','ơ','ø','ǿ','º','œ','Ŕ','Ŗ','ŕ','ŗ','Ŝ','Ș','ŝ','ș',
        'ſ','Ţ','Ț','Ŧ','Þ','ţ','ț','ŧ','þ','Ù','Ú','Û','Ũ','Ŭ','Ű','Ų','Ư','Ǔ','Ǖ','Ǘ','Ǚ','Ǜ','ù','ú','û','ũ','ŭ','ű','ų','ư','ǔ','ǖ','ǘ','ǚ','ǜ','Ŵ','ŵ','Ý','Ÿ','Ŷ','ý','ÿ','ŷ',
        'Ъ','Ь','А','Б','Ц','Ч','Д','Е','Ё','Э','Ф','Г','Х','И','Й','Я','Ю','К','Л','М','Н','О','П','Р','С','Ш','Щ','Т','У','В','Ы','З','Ж','ъ','ь','а','б','ц','ч','д','е','ё','э',
        'ф','г','х','и','й','я','ю','к','л','м','н','о','п','р','с','ш','щ','т','у','в','ы','з','ж', 'Ä','Ö','Ü','ß','ä','ö','ü','Ç','Ğ','İ','Ş','ç','ğ','ı','ş',
        'Ā','Ē','Ģ','Ī','Ķ','Ļ','Ņ','Ū','ā','ē','ģ','ī','ķ','ļ','ņ','ū','Ґ','І','Ї','Є','ґ','і','ї','є','Č','Ď','Ě','Ň','Ř','Š','Ť','Ů','Ž','č','ď','ě','ň','ř','š','ť','ů','ž',
        'Ą','Ć','Ę','Ł','Ń','Ó','Ś','Ź','Ż','ą','ć','ę','ł','ń','ó','ś','ź','ż',
    ];


    let to = [
        1,2,3,0,'ae','ae','A','A','A','A','A','A','A','A','AE','AE','a','a','a','a','a','a','a','a','a','at','C','C','c','c','c','Dj','D','dj','d','E','E',
        'E','E','E','E','e','e','e','e','e','e','f','G','G','g','g','H','H','h','h','I','I','I','I','I','I','I','I','IJ','i','i','i','i','i','i','i','i',
        'ij','J','j','L','L','L','l','l','l','N','n','n','O','O','O','O','O','O','O','O','O','O','OE','o','o','o','o','o','o','o','o','o','o','o','oe','R','R','r','r','S','S','s','s',
        's','T','T','T','TH','t','t','t','th','U','U','U','U','U','U','U','U','U','U','U','U','U','u','u','u','u','u','u','u','u','u','u','u','u','u','W','w','Y','Y','Y','y','y','y',
        '','','A','B','C','Ch','D','E','E','E','F','G','H','I','J','Ja','Ju','K','L','M','N','O','P','R','S','Sh','Shch','T','U','V','Y','Z','Zh','','','a','b','c','ch','d','e','e',
        'e','f','g','h','i','j','ja','ju','k','l','m','n','o','p','r','s','sh','shch','t','u','v','y','z','zh','AE','OE','UE','ss','ae','oe','ue','C','G','I','S','c','g','i','s',
        'A','E','G','I','K','L','N','U','a','e','g','i','k','l','n','u','G','I','Ji','Ye','g','i','ji','ye','C','D','E','N','R','S','T','U','Z','c','d','e','n','r','s','t','u','z',
        'A','C','E','L','N','O','S','Z','Z','a','c','e','l','n','o','s','z','z',
    ];

    for (let key in from) {
        str = str.replace(new RegExp(from[key], 'g'), to[key])
    }

    return str;
}

window.enhancedEcomm = function(type, products, actionField) {
    if(actionField === undefined) {
        let actionField = {};
    }
    window.dataLayer = window.dataLayer || [];

    var $allowedTypes = [
        'productClick',
        'productView',
        'addToCart',
        'removeFromCart',
        'checkout',
        'checkoutOption',
        'purchase',
        'transactionSuccess',
        'promotionImpression',
        'promotionClick',
        'recommended_product_view',
        'recommended_product_add_to_cart',
        'view_cart',
        'add_to_wishlist'
    ];
    if ($allowedTypes.indexOf(type) === -1) {
        return false;
    }

    let dt = {};
    dt = formatEcommGA4(type, actionField, products);

    /*console.log('enhancedEcomm() dt:');
    console.log(dt);*/
    if(dt != null) {
        window.dataLayer.push(dt);
    }
    /*
    window.dataLayer.push({
        event: type,
        ecommerce: ecomm
    });
    */
}

function formatEcommGA4(type, actionField, products)
{
    /*console.log('BEGIN ----------------------------');
    console.log('type:' + type);
    console.log('actionField:');
    console.log(actionField);
    console.log('products:');
    console.log(products);
    console.log('END ----------------------------');*/
    let ecomm = null;
    var productsFormated = [];
    if(typeof(products) != 'undefined') {
        for (let i in products) {
            var p = products[i];
            var fp = {
                item_name: p.name,
                item_id: p.id,
                price: p.price
            };
            if(typeof(p.variant) != 'undefined') {
                fp.item_variant = p.variant;
            }
            if(typeof(p.brand) != 'undefined') {
                fp.item_brand = p.brand;
            }
            if(typeof(p.category) != 'undefined') {
                fp.item_category = p.category;
            }
            if(typeof(p.quantity) != 'undefined') {
                fp.quantity = p.quantity;
            }
            productsFormated[i] = fp;
        }
    }


    ecomm = {
        items: productsFormated
    };

    if (type === 'productClick') {
        type = 'select_item';
    } else if (type === 'productView') {
        type = 'view_item';
    } else if (type === 'addToCart') {
        type = 'add_to_cart';
    } else if (type === 'removeFromCart') {
        type = 'remove_from_cart';
    } else if(type === 'checkoutOption') {
        if(typeof(actionField.step) != 'undefined') {
            if(actionField.step == 1) {
                type = 'add_shipping_info';
                ecomm.shipping_tier = typeof(actionField.option) != 'undefined' ? actionField.option : '';
            } else if(actionField.step == 2) {
                type = 'add_shipping_info';
                ecomm.shipping_tier = typeof(actionField.option) != 'undefined' ? actionField.option : '';
            } else if(actionField.step == 3) {
                type = 'add_payment_info';
                ecomm.payment_type = typeof(actionField.option) != 'undefined' ? actionField.option : '';
            }
        }
    } else if(type === 'checkout') {
        if(actionField.step == 1) {
            type = 'begin_checkout';
        } else {
            return null;
        }
    } else if(type === 'transactionSuccess') {
        type = 'purchase';
        ecomm.currency = 'EUR';
        ecomm.value = actionField.revenue;
        ecomm.tax = actionField.tax;
        ecomm.shipping = actionField.shipping;
        ecomm.coupon = actionField.coupon;
        ecomm.transaction_id = actionField.id;
        ecomm.email = actionField.email;

    } else if (type === 'promotionImpression' || type === 'promotionClick') {
        type = (type === 'promotionImpression' ? 'view_promotion' : 'select_promotion');
        var promotionsFormated = [];
        if(Array.isArray(products)) {
            for (let i in products) {
                var p = products[i];
                var fp = {
                    promotion_id: typeof(p.id) != 'undefined' ? p.id : '',
                    promotion_name: p.name,
                    creative_name: p.creative,
                    creative_slot: typeof(p.slot) != 'undefined' ? p.slot : '',
                    location_id: p.position
                };
                promotionsFormated[i] = fp;
            }
        } else {
            var fp = {
                promotion_id: typeof(products.id) != 'undefined' ? products.id : '',
                promotion_name: products.name,
                creative_name: products.creative,
                creative_slot: typeof(products.slot) != 'undefined' ? products.slot : '',
                position: products.position
            };
            promotionsFormated[0] = fp;
        }

        ecomm = {
            items: promotionsFormated
        };
    }

    return {
        event: type,
        ecommerce: ecomm
    };
}

window.update_product_list_select = function() {
    // modification change update
    $('.product-card input[name^="product_"][name$="_color"]').on('change', function() {
        let modification_id = $(this).val();
        let curr_mod_el = $('#modification-items-' + modification_id + '-wrp');

        // if elements exists
        if (curr_mod_el.length) {
            // hide all modifications items
            curr_mod_el.parent().find('[id^="modification-items-"][id$="-wrp"]').each(function() {
                $(this).hide();
            });

            // display selected modification items
            curr_mod_el.show();

            // update information by selected item
            update_card_by_item(curr_mod_el.find('select option:selected'));
        }
    });

    // item change update
    $('.product-card [id^="modification-items-"][id$="-wrp"] select').on('change', function() {
        update_card_by_item($(this).find('option:selected'));
    });

    function update_card_by_item(selected_item_option) {
        let item_name = selected_item_option.data('name');
        let item_brand = selected_item_option.data('brand');
        let item_price = selected_item_option.data('price');
        let item_price_formated = selected_item_option.data('price-formated');
        let item_regular_price = selected_item_option.data('regular-price');
        let item_regular_price_formated = selected_item_option.data('regular-price-formated');
        let item_quantity = selected_item_option.data('qty');
        let item_id = selected_item_option.attr('value');
        let add2cart_btn = selected_item_option.parents('.product-card').find('[data-action="add2cart"]');

        // update item price
        let price_str = '<span class="text-accent">' + item_price_formated + '</span>';
        if (parseFloat(item_price) < parseFloat(item_regular_price)) {
            price_str = price_str + ' <del class="fs-sm text-muted">' + item_regular_price_formated + '</del>';
        }
        selected_item_option.parents('.product-card').find('.product-price .price-wrp-selected').html(price_str);

        // update button
        if (typeof add2cart_btn != 'undefined') {
            add2cart_btn.data('id', item_id);
            add2cart_btn.data('name', item_name);
            add2cart_btn.data('brand', item_brand);
            add2cart_btn.data('price', item_price);

            if (item_quantity > 0) {
                add2cart_btn.prop('disabled', false);
                add2cart_btn.html(add2cart_btn.data('add-text'));
            } else {
                add2cart_btn.prop('disabled', true);
                add2cart_btn.html(add2cart_btn.data('soldout-text'));
            }
        }
    }
}
